<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <h1 class="font-weight-bold">徳永工務店</h1>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <p>私たち徳永工務店は、佐賀県東松浦郡玄海町で長年にわたり</p>
      <p>建築工事、土木工事、設計業を営んでおります。</p>
      <p>建築、設計に関するご相談、ご質問がありましたらお気軽にお問い合わせください。</p>
    </v-row>

    <v-row>
      <v-carousel
        cycle
        id="carousel"
        height="400"
        hide-delimiter-background
        show-arrows-on-hover
        interval="15000"
      >
        <v-carousel-item
          v-for="(item, i) in slides"
          :key="i"
          :src="item.src"
          reverse-transition="fade"
          transition="fade"
        />
      </v-carousel>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",

  data: () => ({
    slides: [
      {
        src: require("@/assets/images/slides/workplace_image_1.jpg"),
      },
      {
        src: require("@/assets/images/slides/workplace_image_2.jpg"),
      },
      {
        src: require("@/assets/images/slides/workplace_image_3.jpg"),
      },
      {
        src: require("@/assets/images/slides/workplace_image_4.jpg"),
      },
    ],
  }),
  mounted() {
    document.title = "有限会社 徳永工務店 | 佐賀県 玄海町 工務店";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "私たち徳永工務店では佐賀県東松浦郡玄海町で建築工事、土木工事、設計業の対応を行っております。"
      );
  },
};
</script>

<style scoped>
#carousel {
  margin: auto;
}

#carousel {
  width: 95%;
  max-width: 700px;
}

p {
  width: 100%;
}

/* 480px以下に適用されるCSS（スマホ用） */
@media screen and (max-width: 480px) {
  #carousel {
    max-height: 50vh;
    width: 100%;
    margin: auto;
  }
}
</style>